<template>
  <div class="main">
    <div class="personal-Details content-body">
      <a-breadcrumb>
        <a-breadcrumb-item href="">
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item> Settings</a-breadcrumb-item>
      </a-breadcrumb>

      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h4>Change Password</h4>
        </a-col>
      </a-row>
      <div class="body">
        <a-form ref="passwordFormRef" :model="passwordForm" :rules="passwordRules" layout="vertical">
          <a-form-item label="Email" has-feedback name="email">
            <a-input type="text" size="large" placeholder="Enter Email" v-model:value="passwordForm.email" allow-clear autocomplete="off" disabled />
          </a-form-item>
          <a-form-item label="Current Password" class="password" required has-feedback name="password">
            <a-input-password v-model:value="passwordForm.password" size="large" placeholder="Enter your password" allow-clear autocomplete="off" />
          </a-form-item>
          <a-form-item label="New Password" class="new_password" required has-feedback name="new_password">
            <a-input-password v-model:value="passwordForm.new_password" size="large" placeholder="Enter new password" allow-clear autocomplete="off" />
          </a-form-item>
          <a-form-item label="Confirm Password" class="confirm_password" required has-feedback name="confirm_password">
            <a-input-password v-model:value="passwordForm.confirm_password" size="large" placeholder="Enter your password again" allow-clear autocomplete="off" />
          </a-form-item>
        </a-form>

        <a-button type="primary" class="updateBtn" @click.prevent="validateForm">Change Password</a-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { defineComponent, ref, reactive, UnwrapRef, onMounted } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import validate from '../../../services/validator'
import auth from '../../../services/auth'
import commonServices from '../../../services/common'

interface CPassword {
  _id?: string;
  email: string;
  password: string;
  new_password: string;
  confirm_password: string;
}

export default defineComponent({
  components: {
    HomeOutlined
  },
  setup () {
    const passwordFormRef = ref()
    const passwordRules = validate.changePasswordValidation
    const passwordForm: UnwrapRef<CPassword> = reactive({
      email: '',
      password: '',
      new_password: '',
      confirm_password: ''
    })
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const changePassword = async (data: CPassword) => {
      try {
        console.log('data', data)
        if (data.new_password !== data.confirm_password) {
          await notify('Error', 'New password and conform password not match', 'error')
          return
        }
        const responce = await auth.changePassword(data)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
        } else {
          passwordFormRef.value.resetFields()
          await notify('Success', 'Password updated successfully', 'success')
        }
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const validateForm = async () => {
      passwordFormRef.value
        .validate()
        .then(() => {
          changePassword(passwordForm)
        })
        .catch((error: ValidateErrorEntity<CPassword>) => {
          console.log('error', error)
        })
    }
    const getProfileDetails = async () => {
      try {
        const profile = commonServices.getCurrentProfile()
        passwordForm.email = profile.email
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      getProfileDetails()
    })
    return {
      passwordFormRef,
      passwordForm,
      passwordRules,
      changePassword,
      validateForm
    }
  }
})
</script>

<style lang="scss">
.personal-Details {
  .header {
    background: #fff;
    padding: 15px 20px;
    margin: 20px 0px 10px;
    border-radius: 8px;
    h4 {
      color: #646464;
      font-family: "TT Norms Pro Medium";
      font-size: 18px;
      text-align: left;
      margin: 0;
    }
  }
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    .has-error .ant-form-explain {
      text-align: left;
    }
    .ant-input-affix-wrapper{
      border-radius: 5px;
    }
    .ant-form {
      display: flex;
      flex-flow: wrap;
      width: 65%;
      margin: 50px auto;
      justify-content: space-between;
      label {
        color: #646464 !important;
      }
      .ant-upload-picture-card-wrapper {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        .ant-upload-select-picture-card {
          margin-bottom: 0;
          background-color: #d9d9d9;
          border: none;
        }
      }
    }
    .ant-form-vertical .ant-form-item {
      width: 48%;
    }
    .updateBtn {
      background: #72CF97;
      border: 1px solid #72CF97;
      border-radius: 5px;
      width: 250px;
      margin: 0 0 30px 0;
    }
  }
}
</style>
