
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { defineComponent, ref, reactive, UnwrapRef, onMounted } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import validate from '../../../services/validator'
import auth from '../../../services/auth'
import commonServices from '../../../services/common'

interface CPassword {
  _id?: string;
  email: string;
  password: string;
  new_password: string;
  confirm_password: string;
}

export default defineComponent({
  components: {
    HomeOutlined
  },
  setup () {
    const passwordFormRef = ref()
    const passwordRules = validate.changePasswordValidation
    const passwordForm: UnwrapRef<CPassword> = reactive({
      email: '',
      password: '',
      new_password: '',
      confirm_password: ''
    })
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const changePassword = async (data: CPassword) => {
      try {
        console.log('data', data)
        if (data.new_password !== data.confirm_password) {
          await notify('Error', 'New password and conform password not match', 'error')
          return
        }
        const responce = await auth.changePassword(data)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
        } else {
          passwordFormRef.value.resetFields()
          await notify('Success', 'Password updated successfully', 'success')
        }
      } catch (error) {
        console.log('error', error)
        await notify('Error', error.data, 'error')
      }
    }
    const validateForm = async () => {
      passwordFormRef.value
        .validate()
        .then(() => {
          changePassword(passwordForm)
        })
        .catch((error: ValidateErrorEntity<CPassword>) => {
          console.log('error', error)
        })
    }
    const getProfileDetails = async () => {
      try {
        const profile = commonServices.getCurrentProfile()
        passwordForm.email = profile.email
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      getProfileDetails()
    })
    return {
      passwordFormRef,
      passwordForm,
      passwordRules,
      changePassword,
      validateForm
    }
  }
})
